<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <!-- search card -->
    <vx-card
      ref="filterCard"
      v-bind:title="$t('Filters')"
      class="user-list-filters mb-8"
      collapse-action
    >
      <div class="vx-row">
        <!-- FullName -->
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Name") }}</label>
          <vs-input v-model="UserSearch.FullName" class="w-full" name="name" />
        </div>
        <!-- Email -->
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Email") }}</label>
          <vs-input v-model="UserSearch.Email" class="w-full" name="email" />
        </div>
        <!-- PhoneNumber -->
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("PhoneNumber") }}</label>
          <vs-input v-model="UserSearch.PhoneNumber" class="w-full" name="PhoneNumber" />
        </div>
        <!-- UserType -->
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("UserType") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="UserSearch.UserType"
            :options="roles"
            autocomplete
            :reduce="(value) => value.Name"
          />
        </div>
        <!-- IsActive -->
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Status") }}</label>
          <v-select
            class="w-full"
            v-model="UserSearch.IsActive"
            :options="UserStatus"
            autocomplete
            :reduce="(value) => value.value"
          />
        </div>

        <div class="vx-col w-full mt-6">
          <vs-button class="mr-6" @click="SearchUser">{{
            $t("Search")
          }}</vs-button>
          <vs-button type="border" class="mr-6" color="danger" @click="resetModel">{{
            $t("Reset")
          }}</vs-button>
        </div>
      </div>
    </vx-card>

    <div id="data-list-list-view" class="data-list-container">
      <data-view-sidebar
        :isSidebarActive="addNewDataSidebar"
        @closeSidebar="toggleDataSidebar"
        :data="sidebarData"
        :isResetPassword="isResetPassword"
      />

      <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="users">
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <div class="vx-row">
            <vs-button
              type="border"
              icon-pack="feather"
              icon="icon-plus"
              @click="addNewData()"
              >{{ $t("Add New") }}</vs-button
            >
          </div>
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2"
                >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                {{
                  users.length - currentPage * itemsPerPage > 0
                    ? currentPage * itemsPerPage
                    : users.length
                }}
                of {{ users.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage = 4">
                <span>4</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 15">
                <span>15</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 20">
                <span>20</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <template slot="thead">
          <vs-th sort-key="name">{{ $t("User Type") }}</vs-th>
          <vs-th sort-key="Name"> {{ $t("Name") }}</vs-th>
          <vs-th sort-key="Name"> {{ $t("Email") }}</vs-th>
          <vs-th sort-key="Name"> {{ $t("Phone Number") }}</vs-th>
          <vs-th sort-key="IsActive">{{ $t("Status") }}</vs-th>
          <vs-th> {{ $t("Action") }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <p class="product-name font-medium truncate">
                  {{ tr.RoleName }}
                </p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">
                  {{ tr.FullName }}
                </p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">
                  {{ tr.Email }}
                </p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">
                  {{ tr.PhoneNumber }}
                </p>
              </vs-td>
              <vs-td>
                <vs-chip :color="getStatusColor(tr.IsActive)" class="product-order-status"
                  ><p v-if="tr.IsActive">Yes</p>
                  <p v-if="!tr.IsActive">No</p>
                </vs-chip>
              </vs-td>
              <vs-td class="whitespace-no-wrap">
                <router-link
                  :to="{
                    name: 'User-Add-Edit',
                    params: { ID: tr.Id },
                  }"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                >
                  <feather-icon
                    icon="EditIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                /></router-link>

                <feather-icon
                  icon="LockIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  @click.stop="resetPassword(tr)"
                />

                <feather-icon
                  icon="TrashIcon"
                  svgClasses="w-5 h-5 hover:text-danger stroke-current"
                  class="m-2"
                  @click="openConfirm(tr)"
                />
                <vx-tooltip
                  :text="$t('Show Profile')"
                  position="top"
                  style="display: inline"
                >
                  <feather-icon
                    icon="UserIcon"
                    svgClasses="w-5 h-5 hover:text-danger stroke-current"
                    class="m-2"
                    v-if="
                      (tr.RoleName == 'Patient' && tr.PatientID > 0) ||
                      (tr.RoleName == 'Doctor' && tr.DoctorID > 0) ||
                      (tr.RoleName == 'Hospital' && tr.HospitalID > 0)
                    "
                    @click="GoToProfile(tr)"
                  />
                </vx-tooltip>
              </vs-td>
            </tr>
          </tbody>
        </template>
      </vs-table>

      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button class="ml-4 mt-2" type="border" color="danger" @click="Back()">
              {{ $t("Back") }}</vs-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moduleUser from "@/store/user/moduleUser.js";
import DataViewSidebar from "./DataViewSidebar.vue";

export default {
  components: {
    VuePerfectScrollbar,
    vSelect,
    DataViewSidebar,
  },
  data() {
    return {
      UserStatus: [
        { label: "Active", value: true },
        { label: "Inactive", value: false },
      ],
      isResetPassword: false,
      addNewDataSidebar: false,
      sidebarData: {},
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      selected: [],
      search: {
        FullName: "",
        Email: "",
        PhoneNumber: "",
        UserType: "",
        IsActive: null,
      },
      attachment: {},
      activePrompt: false,
      Subject: {},
      itemsPerPage: 10,
      isMounted: false,
      sidebarData: {},
      rowDataForDelete: {},
      activeConfirm: false,
      showSearchSidebar: false,
    };
  },
  computed: {
    UserSearch() {
      debugger;
      return this.$store.state.UserList.UserSearchObj;
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    validateSubjectForm() {
      return this.Subject.Subject != undefined;
    },
    users() {
      debugger;
      return this.$store.state.UserList.users;
    },
    validateAttachmentForm() {
      return this.attachment.SpecialtyID != undefined;
    },
    roles() {
      return this.$store.state.UserList.roles;
    },
  },

  methods: {
    resetModel() {
      this.UserSearch.FullName = "";
      this.UserSearch.Email = "";
      this.UserSearch.PhoneNumber = "";
      this.UserSearch.UserType = "";
      this.UserSearch.IsActive = null;
    },
    resetPassword(data) {
      this.sidebarData = data;
      this.isResetPassword = true;

      this.toggleDataSidebar(true);
    },

    addNewData() {
      this.isResetPassword = false;
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
    resetPassword(data) {
      this.sidebarData = data;
      this.isResetPassword = true;

      this.toggleDataSidebar(true);
    },
    editData(data) {
      this.isResetPassword = false;
      this.sidebarData = data;
      //in edit password not editable so fill it with any pass to can save because it is required
      this.sidebarData.Password = "Abc_123456";
      this.toggleDataSidebar(true);
    },
    GoToProfile(data) {
      debugger;
      if (data.RoleName == "Patient") {
        debugger;
        this.$router.push({
          name: "PatientEdit",
          params: { ID: data.PatientID },
        });
      }
      if (data.RoleName == "Doctor") {
        debugger;
        this.$router.push({
          name: "doctor-add/Edit",
          params: { ID: data.DoctorID },
        });
      }
      if (data.RoleName == "Hospital") {
        debugger;
        this.$router.push({
          name: "Hospital-add/Edit",
          params: { ID: data.HospitalID },
        });
      }
      debugger;
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    SaveAttachment() {
      this.attachment.UserID = this.$route.params.ID;
      this.listOfSubjectAttachment.push(this.attachment);
      this.UserModel.UserSpecialties = this.listOfSubjectAttachment;
      this.clearAttachmentModal();
    },
    clearAttachmentModal() {
      this.attachment = {};
    },
    getStatusColor(status) {
      if (status == true) return "success";
      if (status == false) return "danger";
      return "warning";
    },

    clearSubjectModal() {
      this.Subject = {};
    },

    addNewData() {
      this.$router.push("/User-Add-Edit");
    },

    Back() {
      this.$router.go(-1);
    },

    deleteData(data) {
      this.$vs.loading();
      if (data.Id) {
        this.$store
          .dispatch("UserList/removeUser", data.Id)
          .then(() => {
            window.showDeleteSuccess();
            this.$vs.loading.close();
          })
          .catch((err) => {
            if (err.response.status == 400) {
              this.$vs.loading.close();

              window.showDeleteFailed(err.response.data.message);
            } else {
              this.$vs.loading.close();
              window.showDeleteFailed("");
            }
          });
      } else {
        this.$vs.loading.close();
        const ItemIndex = this.$store.state.UserList.users.findIndex(
          (p) => p.SubjectID == data.SubjectID
        );
        //if(ItemIndex!=-1)
        this.$store.state.UserList.users.splice(ItemIndex, 1);
      }
    },

    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.sidebarData = data;
      this.toggleDataSidebar(true);
    },

    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },

    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
    },
    SearchUser() {
      this.$vs.loading();

      this.$store.dispatch("UserList/UserSearch", this.UserSearch).then(() => {
        this.$vs.loading.close();
      });
    },
  },

  created() {
    if (!moduleUser.isRegistered) {
      this.$store.registerModule("UserList", moduleUser);
      moduleUser.isRegistered = true;
    }
    this.$vs.loading();

    this.$store.dispatch("UserList/fetchDataListItems").then(() => {
      this.$vs.loading.close();
    });
    this.$store.dispatch("UserList/GetAllRoles");
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
