<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>
        {{
          isResetPassword
            ? "Reset Password"
            : Object.entries(this.data).length === 0
            ? "ADD NEW ITEM"
            : "UPDATE ITEM"
        }}
      </h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar
      class="scroll-area--data-list-add-new"
      :settings="settings"
    >
      <div class="p-6">
        <div v-if="!isResetPassword">
          <p class="mt-5" style="font-size: 12px;">User Type</p>
          <v-select
            label="label"
            class="w-full"
            autocomplete
            v-if="!isResetPassword"
            v-model="UserType"
            :options="UserTypeList"
            :reduce="value => value.value"
            @input="getUsers"
          />

          <p v-if="UserType == 3" class="mt-5" style="font-size: 12px;">
            Instructor
          </p>
          <v-select
            label="Name"
            class="w-full"
            autocomplete
            v-model="InstructorID"
            :options="instructors"
            :reduce="ID => ID.ID"
            @input="selectInstructor"
            @search="FilterInstructor"
            v-if="UserType == 3"
          />

          <p v-if="UserType == 2" class="mt-5" style="font-size: 12px;">
            Trainee
          </p>
          <v-select
            label="FullName"
            class="w-full"
            autocomplete
            v-model="TraineeID"
            :options="trainees"
            :reduce="ID => ID.ID"
            @input="selectTrainee"
            @search="FilterTrainee"
            v-if="UserType == 2"
          />

          <p v-if="UserType == 1" class="mt-5" style="font-size: 12px;">
            Custromer
          </p>
          <v-select
            label="FullName"
            class="w-full"
            autocomplete
            v-model="CustomerID"
            :options="customers"
            :reduce="ID => ID.ID"
            @input="selectCustomer"
            @search="FilterCustomer"
            v-if="UserType == 1"
          />
        </div>

        <!-- NAME -->
        <vs-input
          :disabled="isResetPassword"
          label="Full Name"
          v-model="FullName"
          class="mt-5 w-full"
          name="item-name"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('Name')">{{
          errors.first("Name")
        }}</span>

        <!-- Email -->
        <vs-input
          v-if="!isResetPassword"
          label="Email"
          v-model="Email"
          class="mt-5 w-full"
          name="Email"
          type="Email"
          v-validate="'required|email|min:3'"
        />
        <span class="text-danger text-sm" v-show="errors.has('Email')">{{
          errors.first("Email")
        }}</span>

        <!-- PhoneNumber -->
        <vs-input
          v-if="!isResetPassword"
          label="Phone Number"
          v-model="PhoneNumber"
          class="mt-5 w-full"
          name="PhoneNumber"
          type="number"
        />

        <!-- user name -->
        <vs-input
          v-if="!isResetPassword"
          label="User Name"
          v-model="UserName"
          type="text"
          class="mt-5 w-full"
          :disabled="Id != ''"
          name="username"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('username')">{{
          errors.first("username")
        }}</span>

        <!-- password -->
        <vs-input
          icon-pack="feather"
          label-placeholder="Password"
          icon-after="true"
          :type="passwordType"
          icon="icon icon-eye-off"
          @icon-click="showPassword()"
          label="Password"
          v-model="Password"
          class="mt-5 w-full"
          @input="checkPassword"
          name="password"
          :disabled="Id != '' && !isResetPassword"
          v-validate="'required|min:8'"
        />
        <span class="text-danger text-sm" v-show="errors.has('password')">{{
          errors.first("password")
        }}</span>

        <ul class="ml-2 mt-3">
          <li v-bind:class="{ is_valid: contains_eight_characters }">
            8 Characters
          </li>
          <li v-bind:class="{ is_valid: contains_number }">Contains Number</li>
          <li v-bind:class="{ is_valid: contains_uppercase }">
            Contains Uppercase
          </li>
          <li v-bind:class="{ is_valid: contains_special_character }">
            Contains Special Character
          </li>
        </ul>

        <p v-if="!isResetPassword" class="mt-5" style="font-size: 12px;">
          Role
        </p>
        <v-select
          label="Name"
          class="w-full"
          v-model="RoleName"
          :options="roles"
          :reduce="Name => Name.Name"
          v-if="!isResetPassword"
          v-validate="'required'"
        />

        <span class="text-danger text-sm" v-show="errors.has('Role')">{{
          errors.first("Role")
        }}</span>

        <vs-checkbox
          v-if="!isResetPassword"
          color="success"
          class="mt-5"
          v-model="IsActive"
          >Is Active</vs-checkbox
        >
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button
        v-if="isResetPassword"
        class="mr-6"
        @click="ResetPassword"
        :disabled="!isFormValid"
        >Submit</vs-button
      >
      <!-- <vs-button
        v-if="!isResetPassword"
        class="mr-6"
        @click="submitData"
        :disabled="!isFormValid"
        >Submit</vs-button
      > -->
      <vs-button
        type="border"
        color="danger"
        @click="isSidebarActiveLocal = false"
        >Cancel</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";
export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    isResetPassword: {
      type: Boolean,
      required: false
    },
    data: {
      type: Object,
      default: () => {}
    }
  },

  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
        this.UserType = "";
      } else {
        let {
          Id,
          UserName,
          FullName,
          PhoneNumber,
          Email,
          Password,
          RoleName,
          IsActive,
          UserType,
          RelatedUser
        } = JSON.parse(JSON.stringify(this.data));
        this.Id = Id;
        this.UserName = UserName;
        this.FullName = FullName;
        if (this.isResetPassword) this.Password = "";
        else this.Password = Password;
        this.RoleName = RoleName;
        this.IsActive = IsActive;
        this.Email = Email;
        this.PhoneNumber = PhoneNumber;
        this.UserType = UserType;
        this.checkPassword();
        if (UserType != null && UserType == 1) this.CustomerID = RelatedUser;
        else if (UserType != null && UserType == 2)
          this.TraineeID = RelatedUser;
        else if (UserType != null && UserType == 3)
          this.InstructorID = RelatedUser;
      }
    }
  },
  data() {
    return {
      passwordType: "password",
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,

      Id: "",
      UserTypeList: [
        { label: "Customer", value: 1 },
        { label: "Trainee", value: 2 },
        { label: "Instructor", value: 3 }
      ],
      UserType: "",
      UserName: "",
      FullName: "",
      Password: "",
      RoleName: "",
      Email: "",
      IsActive: false,
      CustomerID: 0,
      TraineeID: 0,
      InstructorID: 0,
      Customer: { CustomerContacts: [] },
      Trainee: { TraineeContacts: [] },
      Instructor: { InstructorContacts: [] },
      PhoneNumber: "",
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },

  computed: {
    customers() {
      return this.$store.state.customerList.customers;
    },
    trainees() {
      return this.$store.state.traineeList.trainees;
    },
    instructors() {
      return this.$store.state.instructorList.instructors;
    },
    roles() {
      return this.$store.state.UserList.roles;
    },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    isFormValid() {
      if (this.isResetPassword)
        return (
          this.Password &&
          this.contains_eight_characters &&
          this.contains_number &&
          this.contains_uppercase &&
          this.contains_special_character
        );
      else
        return (
          !this.errors.any() &&
          this.UserName &&
          this.Password &&
          this.FullName &&
          this.RoleName &&
          this.Email &&
          this.contains_eight_characters &&
          this.contains_number &&
          this.contains_uppercase &&
          this.contains_special_character
        );
    }
  },
  methods: {
    showPassword() {
      if (this.passwordType == "text") this.passwordType = "password";
      else this.passwordType = "text";
    },
    checkPassword() {
      this.password_length = this.Password.length;
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

      if (this.password_length > 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }

      this.contains_number = /\d/.test(this.Password);
      this.contains_uppercase = /[A-Z]/.test(this.Password);
      this.contains_special_character = format.test(this.Password);

      if (
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    },
    getUsers() {
      // this.$store.dispatch("instructorList/fetchDataListItemsByFilter"," ");
      //     this.$store.dispatch("traineeList/fetchDataListItemsByFilter"," ");
      //     this.$store.dispatch("customerList/fetchDataListItemsByFilter"," ");
    },

    initValues() {
      if (this.data.Id) return;
      this.Id = "";
      this.UserName = "";
      this.FullName = "";
      this.Password = "";
      this.RoleName = "";
      this.Email = "";
      this.PhoneNumber = "";
      this.IsActive = false;
    },

    ResetPassword() {
      const obj = {
        userID: this.Id,
        newPassword: this.Password
      };

      this.$store
        .dispatch("UserList/UpdatePassword", obj)
        .then(() => {
          this.$vs.notify({
            color: "sucess",
            time: 8000,
            title: "sucess",
            text: "Password Changed Successfully"
          });
          this.$emit("closeSidebar");
          this.initValues();
        })
        .catch(err => {
          this.$vs.notify({
            time: 8000,
            color: "danger",
            title: "error",
            text: err.data
          });
          console.error(err);
        });
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect
  }
};
</script>

<style>
.is_valid {
  color: rgba(136, 152, 170, 0.8);
}
.is_valid:before {
  width: 50%;
}
li {
  margin-bottom: 8px;
  color: #525f7f;
  position: relative;
}

li:before {
  content: "";
  width: 0%;
  height: 2px;
  background: #2ecc71;
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
</style>
<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
